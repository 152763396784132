import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'dwa-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent {
	title = 'dewey-app';

	constructor(private translate: TranslateService) {
		this.setupTranslations();
	}

	private setupTranslations(): void {
		const browserLang = this.translate.getBrowserLang();
		this.translate.addLangs(['en', 'de']);
		this.translate.setDefaultLang('en');
		if (browserLang && this.translate.getLangs().includes(browserLang)) {
			this.translate.use(browserLang);
		} else {
			this.translate.use(this.translate.getDefaultLang());
		}
	}
}
